<template>
  <div class="pageContol listWrap templateList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">运营工具</a>
          <i>></i>
          <a href="javascript:;" class="cur-a">赠送题库精品课</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="operationControl flexdc" style="align-items: flex-start">
          <div class="searchbox" style="margin-bottom: 15px;">
            <div title="赠送内容" class="searchboxItem ci-full">
              <span class="itemLabel">赠送内容:</span>
              <el-input
                v-model="content"
                clearable
                placeholder="请输入赠送内容"
                size="small"
              ></el-input>
            </div>
            <div title="赠送类别" class="searchboxItem ci-full">
              <span class="itemLabel">赠送类别:</span>
              <el-select
                v-model="giveType"
                placeholder="请选择"
                size="small"
                clearable
              >
                <el-option
                  v-for="item in giveTypeList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </div>
            <div title="类型" class="searchboxItem ci-full">
              <span class="itemLabel">类型:</span>
              <el-select
                v-model="giveMode"
                placeholder="请选择"
                size="small"
                clearable
              >
                <el-option
                  v-for="item in giveModeList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </div>
          </div>
          <div class="searchbox">
            <div title="赠送日期" class="searchboxItem ci-full">
              <span class="itemLabel">赠送日期:</span>
              <el-date-picker
                clearable
                size="small"
                v-model="signDate"
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                format="yyyy-MM-dd"
                value-format="yyyy-MM-dd HH:mm:ss"
                :default-time="['00:00:00', '23:59:59']"
              ></el-date-picker>
            </div>
            <div class="df" style="padding-left: 10px">
              <el-button type="primary" class="bgc-bv" round @click="getData()"
                >查询</el-button
              >
              <el-button type="primary" class="bgc-bv" round @click="addGive(false)"
                >新增</el-button
              >
              <el-button type="primary" class="bgc-bv" round @click="addGive(true)"
                >批量赠送</el-button
              >
              <el-button type="primary" class="bgc-bv" round @click="exportList()"
                >导出</el-button
              >
            </div>
          </div>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table
              ref="multipleTable"
              :data="tableData"
              :height="tableHeight"
              size="small"
              tooltip-effect="dark"
              style="width: 100%"
              :header-cell-style="tableHeader"
              stripe
            >
              <el-table-column
                label="序号"
                align="center"
                type="index"
                :index="indexMethod"
                fixed
              />
               <el-table-column
                label="类型"
                align="center"
                show-overflow-tooltip
                prop="giveMode"
                min-width="100"
              >
                <template slot-scope="scope">
                  {{ scope.row.giveMode && $setDictionary("USER_GIVE_MODE", scope.row.giveMode) }}
                </template>
              </el-table-column>
              <el-table-column
                label="赠送类别"
                align="center"
                show-overflow-tooltip
                prop="giveType"
                min-width="100"
              >
                <template slot-scope="scope">
                  {{ $setDictionary("USER_GIVE_TYPE", scope.row.giveType) }}
                </template>
              </el-table-column>
              <el-table-column
                label="赠送内容"
                align="center"
                show-overflow-tooltip
                prop="resourceContent"
                min-width="200"
              />
              <el-table-column
                label="有效期(至)"
                align="center"
                show-overflow-tooltip
                prop="validTime"
                min-width="120"
              />
              <el-table-column
                label="操作人"
                align="center"
                show-overflow-tooltip
                prop="operationName"
                min-width="150"
              />
              <el-table-column
                label="操作时间"
                align="center"
                show-overflow-tooltip
                prop="createTime"
                min-width="150"
              >
                <template slot-scope="scope">
                  {{ scope.row.createTime | moment }}
                </template>
              </el-table-column>
              <el-table-column
                label="赠送原因"
                align="center"
                show-overflow-tooltip
                prop="giveReason"
                min-width="150"
              />
              <el-table-column
                label="操作"
                align="center"
                width="320px"
                fixed="right"
              >
                <div slot-scope="scope">
                  <el-button
                    type="text"
                    size="mini"
                    style="padding: 0 5px"
                    :disabled="scope.row.cancelState"
                    @click="giveCancel(scope.row.giveId)"
                    >{{ scope.row.cancelState ? "已撤销" : "撤销" }}</el-button
                  >
                  <el-button
                    type="text"
                    size="mini"
                    style="padding: 0 5px"
                    @click="examineRoll(scope.row.giveId)"
                    >查看名单</el-button>
                  <el-button
                    type="text"
                    size="mini"
                    style="padding: 0 5px"
                    @click="uploadFile(scope.row.giveId)"
                    >查看附件</el-button>
                </div>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum
          :apiData="apiData"
          @sizeChange="sizeChange"
          @getData="getData"
        />
      </div>
    </div>
    <el-dialog
      title="赠送"
      :visible.sync="dialogVisible"
      width="55%"
      top="1%"
      :center="true"
      v-if="dialogVisible"
      @close="canCleForm"
    >
      <div class="ovy-a" style="height: 500px">
        <el-form label-width="120px" ref="Form" :model="Form" :rules="rules">
          <template v-if="!ifBatch">
            <el-form-item label="手机号码" prop="userId">
              <el-select
                size="small"
                v-model="Form.userId"
                remote
                :remote-method="getMobile"
                filterable
                clearable
                placeholder="请输入手机号搜索"
              >
                <el-option
                  v-for="item in mobileList"
                  :key="item.userId"
                  :label="item.mobile + '(' + item.userName + ')'"
                  :value="item.userId"
                ></el-option>
              </el-select>
            </el-form-item>
          </template>
          <template v-else>
            <el-form-item label="下载模板">
             <el-button type="text" @click="downloadTheTemplate">批量导入模板.xls</el-button>
            </el-form-item>
            <el-form-item label="上传文件" prop="fileKey">
              <div class="df">
                <el-upload
                  class="upload-demo upload-btn my-btn"
                  :action="actionUrl"
                  :on-error="handleError"
                  :on-success="handleSuccess"
                  :on-change="uploadChange2"
                  :show-file-list="false"
                  :auto-upload="false"
                >
                  <el-button class="bgc-bv" size="mini">上传文件</el-button>
                </el-upload>
                <p v-if="Form.fileName" style="margin-left: 10px">
                  当前选择文件：
                  <span style="color: #f46173">{{ Form.fileName }}</span>
                </p>
                <p v-else style="margin-left: 10px">
                  未选择文件
                </p>
              </div>
              <div style="margin-top: 10px" v-if="progressVal > 0">
                <el-progress
                  :text-inside="true"
                  :stroke-width="20"
                  :percentage="progressVal"
                ></el-progress>
              </div>
            </el-form-item>
          </template>
          <el-form-item label="赠送类别" prop="giveType">
            <el-select
              v-model="Form.giveType"
              clearable
              placeholder="请选择赠送类别"
              size="small"
            >
              <el-option
                v-for="item in giveTypeList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            label="赠送内容"
            prop="courseId"
            v-if="Form.giveType == '20'"
          >
            <el-select
              clearable
              size="small"
              v-model="Form.courseId"
              multiple
              filterable
              remote
              reserve-keyword
              :remote-method="getCourse"
              placeholder="请至少输入两个字搜索课程"
              @change="
                (val) => {
                  remoteChange(val, index);
                }
              "
              @remove-tag="removeTagCourse"
            >
              <el-option
                size="small"
                v-for="item in courseList"
                :key="item.courseId"
                :label="item.courseName"
                :value="item.courseId"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="赠送内容" prop="questionBankId" v-else>
            <el-select
              clearable
              size="small"
              v-model="Form.questionBankId"
              multiple
              filterable
              remote
              :remote-method="getQuestionBankName"
              reserve-keyword
              placeholder="请至少输入两个字搜索题库"
              @change="
                (val) => {
                  questionBankChange(val, index);
                }
              "
              @remove-tag="removeTag"
            >
              <el-option
                size="small"
                v-for="item in questionBankList"
                :key="item.questionBankId"
                :label="item.questionBankName"
                :value="item.questionBankId"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="有效期至" prop="validTimeStr">
            <el-date-picker
              v-model="Form.validTimeStr"
              type="date"
              format="yyyy-MM-dd"
              value-format="yyyy-MM-dd"
              :picker-options="pickerOptions"
              placeholder="有效期至">
            </el-date-picker>
          </el-form-item>
          <el-form-item label="赠送原因" prop="giveReason">
            <el-input
              v-model="Form.giveReason"
              type="textarea"
              size="small"
              placeholder="请输入赠送原因"
            />
          </el-form-item>
        </el-form>
        <div class="export-box" v-if="ifBatch">
          <h3>
            <span>导入结果</span>
          </h3>
          <div class="df studentNum" style="margin:1rem 0">
            <span>赠送人数总数量:{{ totalNum }}人;</span>
            <span>成功:{{ correctNum }}人;</span>
            <span>失败:{{ errorNum }}人;</span>
          </div>
          <div>
            <el-button
              class="bgc-bv"
              size="mini"
              :disabled="errorNum == '0'"
              @click="doExportError"
              >导出错误数据</el-button
            >
          </div>
          <div style="margin-top:15px;color:#DD1D35;" v-if="errorNum != 0">
            有导入失败人数，请导出错误数据，将导入失败人数调整后重新上传
          </div>
        </div>
      </div>
      <span
        slot="footer"
        class="dialog-footer"
        style="display: flex; justify-content: center"
      >
        <el-button class="bgc-bv" @click="canCleForm('Form')">取消</el-button>
        <el-button class="bgc-bv" @click="getSure('Form')">确定</el-button>
      </span>
    </el-dialog>
    <!-- 上传附件 - loading -->
    <el-dialog
      title="上传附件"
      :visible.sync="uploadFileDialog"
      width="35%"
      class="toVoidLoding"
    >
      <div style="margin: 10 0 10px">
        <el-upload
          class="upload-demo upload-btn upload-btndrag"
          :action="actionUrl"
          :on-error="handleError"
          :on-success="handleSuccess"
          :on-change="uploadChange"
          :show-file-list="false"
          :auto-upload="false"
          drag
        >
          <i class="el-icon-upload"></i>
          <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
        </el-upload>
        <div
          v-if="uploadFileList.length"
          style="margin-top: 10px; margin-left: 10px"
        >
          当前选择文件：
          <div style="display: flex; flex-direction: column">
            <span
              style="color: #409eff"
              v-for="(item, index) in uploadFileList"
              :key="index"
            >
              {{ item.annexName }}
              <!-- <a @click="reomveExl" style="color: #5bb5ff;margin:0 15px">预览</a> -->
              <a @click="lookExl(item)" style="color: #409eff; margin: 0 15px"
                >预览</a
              >
              <a
                @click="removeExl(index, item.annexId)"
                style="color: red; margin: 0 15px"
                >删除</a
              >
            </span>
          </div>
        </div>
        <div v-else>
          <p style="margin-top: 10px; margin-left: 10px">未选择文件</p>
        </div>
      </div>
    </el-dialog>
    <!-- 预览附件 - loading -->
    <el-dialog
      title="预览"
      :visible.sync="previewLoading"
      width="50%"
      top="2%"
      center
    >
      <div class="ovy-a">
        <div
          id="pdf-cert1"
          style="height: 600px"
          v-if="annexType == 'pdf' || annexType == 'PDF'"
        ></div>
        <div v-else>
          <img :src="ImgSrc" alt="" width="100%" height="100%" />
        </div>
      </div>
    </el-dialog>
    <el-dialog
      title="查看名单"
      width="800px"
      :visible.sync="checkTheListState"
      @close="canCleList">
      <div>
        <div class="operationControl flexdc" style="align-items: flex-start">
          <div class="searchbox" style="margin-bottom: 15px;">
            <div title="姓名" class="searchboxItem ci-full">
              <span class="itemLabel" style="min-width: 3rem">姓名:</span>
              <el-input
                v-model="dialogData.userName"
                clearable
                placeholder="请输入姓名"
                size="small"
              ></el-input>
            </div>
            <div title="手机号" class="searchboxItem ci-full">
              <span class="itemLabel" style="min-width: 4rem">手机号:</span>
              <el-input
                v-model="dialogData.mobile"
                clearable
                placeholder="请输入手机号"
                size="small"
              ></el-input>
            </div>
            <div class="df" style="padding-left: 10px">
              <el-button type="primary" class="bgc-bv" round @click="getExamineRoll()"
                >查询</el-button
              >
            </div>
          </div>
        </div>
        <el-table
          :data="checkTheList"
          size="small"
          tooltip-effect="dark"
          :height="500"
          style="width: 100%"
          :header-cell-style="tableHeader"
          stripe>
          <el-table-column
            label="序号"
            align="center"
            type="index"
            :index="indexMethod2"
            fixed
          />
          <el-table-column
            label="姓名"
            align="center"
            show-overflow-tooltip
            prop="userName"
          />
          <el-table-column
            label="手机号"
            align="center"
            show-overflow-tooltip
            prop="mobile"
          />
        </el-table>
        <PageNum
          style="margin-top: 1rem;"
          :apiData="apiData2"
          @sizeChange="sizeChange2"
          @getData="getExamineRoll"
        />
      </div>
    </el-dialog>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import pdf from "pdfobject";
import { mapGetters } from "vuex";
import { resetKeepAlive } from "@/utils/common";
export default {
  name: "activeConfigurationList",
  components: {
    Empty,
    PageNum,
  },
  mixins: [List],
  data() {
    return {
      content: "", //赠送内容
      signDate: "", //赠送日期
      giveType: "", //赠送类别
      giveMode: "", //赠送类型
      giveTypeList: [], //赠送类别
      giveModeList: [], //赠送类型
      ifBatch:false,//是否批量
      dialogVisible: false,
      Form: {
        mobile: "", //手机号
        giveType: "", //赠送类别
        giveReason: "", //赠送原因
        validTimeStr: "", //有效期至
        fileName: "", //文件名称
        fileKey: "", //文件key
        questionBankId:[],
        courseId:[],
        resourceContent: [],
      },
      pickerOptions: {
        disabledDate: this.disabledDate
      },
      rules: {
        userId: [{ required: true, message: "请选择手机号", trigger: "blur" }],
        fileKey: [
          { required: true, message: "请选择上传文件", trigger: "change" },
        ],
        giveType: [
          { required: true, message: "请选择赠送类别", trigger: "change" },
        ],
        validTimeStr: [{ required: true, message: "请选择有效期", trigger: "change" }],
        courseId: [
          { required: true, message: "请选择课程", trigger: "change" },
        ],
        questionBankId: [
          { required: true, message: "请选择题库", trigger: "change" },
        ],
      },
      courseList: [], //课程数据
      questionBankList: [], //题库数据
      uploadFileDialog: false, //上传附件弹窗
      uploadFileList: [],
      previewLoading: false, //预览
      annexType: "",
      ImgSrc: "",
      mobileList: [],
      // 导入
      progressVal:0,
      totalNum: "0",
      errorNum: "0",
      correctNum: "0",
      batchId: "",
      importNum: "0",
      errorFileUrl:'',
      // 查看名单
      checkTheListState:false,
      checkTheList:[],
      dialogData:{
        userName:'',
        mobile:''
      },
      apiData2: {
        total: 0,
        size: 10,
        current: 1,
      },
      pageSize2: 10,
      pageNum2: 1,
    };
  },
  watch: {},
  created() {
    this.getGiveType();
    this.getGiveMode();
  },
  computed: {
    ...mapGetters({
      downloadItems: "getDownloadItems",
    }),
  },
  mounted() {},
  methods: {
    
    disabledDate(date) {
        const minDate = new Date()
        const diff = Math.abs(minDate.valueOf() - date.valueOf());
        if (diff > 1000 * 3600 * 24 * 365) {
          return true;
        }
        if(date.getTime() < Date.now() - 8.64e7){
          return true;
        }
    },
    // 初始化获取评论列表
    getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
      };
      if (this.content) {
        params.content = this.content;
      }
      if (this.signDate&&this.signDate.length===2) {
        params.startTime = this.signDate[0];
        params.endTime = this.signDate[1];
      }
      if (this.giveType) {
        params.giveType = this.giveType;
      }
      if (this.giveMode) {
        params.giveMode = this.giveMode;
      }
      this.doFetch(
        {
          url: "/biz/userGive/pageList",
          params,
          pageNum,
        },
        true,
        2
      );
    },
    //获取码值赠送类别
    getGiveType() {
      const List = this.$setDictionary("USER_GIVE_TYPE", "list");
      for (const key in List) {
        this.giveTypeList.push({
          value: key,
          label: List[key],
        });
      }
    },
    //获取码值赠送类别
    getGiveMode() {
      const List = this.$setDictionary("USER_GIVE_MODE", "list");
      for (const key in List) {
        this.giveModeList.push({
          value: key,
          label: List[key],
        });
      }
    },
    getMobile(query) {
      if (query.length == 11) {
        this.$post("/biz/user/queryUserList", {
          // pageNum: 1, //每次都只要第一页
          // pageSize: 5, //根据次参数控制显示的数据行数，尽量不出现滚动条
          mobile: query,
        })
          .then((res) => {
            this.mobileList = res.data.list;
          })
          .catch((err) => {
            return;
          });
      }
    },

    //课程搜索
    getCourse(query) {
      if (query.trim().length >= 2) {
        this.$post(
          "minicourse/operation/selectList",
          { courseName: query },
          3000,
          true,
          2
        )
          .then((res) => {
            if (res.status == 0) {
              this.courseList = res.data || [];
            }
          })
          .catch(() => {
            return;
          });
      } else {
        this.courseList = [];
      }
      //
    },
    //精品课选择change事件
    remoteChange(val, index) {
       if (val) {
        this.courseList.forEach((item) => {
          val.forEach((el) => {
            if (item.courseId == el) {
              this.Form.resourceContent.push({
                courseId: item.courseId,
                courseName: item.courseName,
              });
            }
          });
          let obj = {};
          let peon = this.Form.resourceContent.reduce((cur, next) => {
            obj[next.courseId]
              ? ""
              : (obj[next.courseId] = true && cur.push(next));
            return cur;
          }, []); //设置cur默认类型为数组，并且初始值为空的数组
          this.Form.resourceContent = peon;
          //筛选出对应数据
        });
      } else {
        this.Form.resourceContent = [];
      }
    },
    //精品课tag移除
      removeTagCourse(tag) {
      this.Form.resourceContent.map((el, index) => {
        if (el.courseId == tag) {
          this.Form.resourceContent.splice(index, 1);
        }
      });
      console.log(this.Form.resourceContent);
    },
    getQuestionBankName(query) {
      if (query.trim().length >= 2) {
        this.$post(
          "/question/bank/selectSaleQbBank",
          { questionBankName: query },
          3000,
          true,
          2
        )
          .then((res) => {
            if (res.status == 0) {
              this.questionBankList = res.data || [];
            }
          })
          .catch(() => {
            return;
          });
      } else {
        this.questionBankList = [];
      }
    },
    //题库选择change事件
    questionBankChange(val, index) {
      if (val) {
        this.questionBankList.forEach((item) => {
          val.forEach((el) => {
            if (item.questionBankId == el) {
              this.Form.resourceContent.push({
                questionBankId: item.questionBankId,
                questionBankName: item.questionBankName,
              });
            }
          });
          let obj = {};
          let peon = this.Form.resourceContent.reduce((cur, next) => {
            obj[next.questionBankId]
              ? ""
              : (obj[next.questionBankId] = true && cur.push(next));
            return cur;
          }, []); //设置cur默认类型为数组，并且初始值为空的数组
          this.Form.resourceContent = peon;
          //筛选出对应数据
        });
      } else {
        this.Form.resourceContent = [];
      }
    },
    //题库tag移除
    removeTag(tag) {
      this.Form.resourceContent.map((el, index) => {
        if (el.questionBankId == tag) {
          this.Form.resourceContent.splice(index, 1);
        }
      });
      console.log(this.Form.resourceContent);
    },
    //确认
    getSure(ruleForm) {
      this.$refs[ruleForm].validate((valid) => {
        if (valid) {
          let parameter = {
            giveReason: this.Form.giveReason,
            validTimeStr: this.Form.validTimeStr,
            giveType: this.Form.giveType,
          };
          let url
          if(this.ifBatch){
            // 批量
            url = '/biz/userGive/import/insert'
            parameter = {
              ...parameter,
              userFileKey: this.Form.fileKey,
            }
          }else{
            // 单个
            url = '/biz/userGive/insert'
            parameter = {
              ...parameter,
              userId: this.Form.userId,
            }
          }
          let ids = [];
          if (this.Form.giveType == "10") {
            for (const key in this.Form.resourceContent) {
              // 删除id属性
              // delete this.Form.resourceContent[key].questionBankId;
              ids.push(this.Form.resourceContent[key].questionBankName);
            }
            parameter.resourceIds = this.Form.questionBankId;
            parameter.resourceContent = ids.toString();
          } else {
            for (const key in this.Form.resourceContent) {
              // 删除id属性
              // delete this.Form.resourceContent[key].courseId;
              ids.push(this.Form.resourceContent[key].courseName);
            }
            parameter.resourceIds = this.Form.courseId;
            parameter.resourceContent = ids.toString();
          }
          this.$post(url, parameter, 3000, true, 2).then(
            (res) => {
              if (res.status == 0) {
                if(this.ifBatch){
                  this.doProgress(res.data);
                }else{
                  this.$message({
                    message: "新增成功",
                    type: "success",
                  });
                  this.canCleForm();
                }
              }
            }
          );
        }
      });
    },
    canCleForm() {
      this.dialogVisible = false;
      this.Form = {
        mobile: "", //手机号
        giveType: "", //赠送类别
        giveReason: "", //赠送原因
        validTimeStr: "", //有效期至
        fileName: "", //文件名称
        fileKey: "", //文件key
        questionBankId:[],
        courseId:[],
        resourceContent: [],
      };
      this.totalNum = 0;
      this.errorNum = 0;
      this.correctNum = 0;
      this.progressVal = 0;
      this.mobileList = [];
      this.getData(-1);
    },
    //新增||编辑
    addGive(ifBatch) {
      this.ifBatch = ifBatch
      this.dialogVisible = true;
    },
    //取消赠送
    giveCancel(giveId) {
      this.cancelCheck(giveId);
      // this.doDel(
      //   {
      //     url: "/biz/userGive/cancel",
      //     msg: "你确定要赠送撤销吗？",
      //     ps: {
      //       type: "post",
      //       data: { giveId },
      //     },
      //   },
      //   true,
      //   2
      // );
    },
    cancelCheck(giveId) {
      this.$post("/biz/userGive/cancelCheck", { giveId }, 3000, true, 2).then(
        (res) => {
          if (res.status == "0") {
            if (res.data.tipsState) {
              this.$confirm(res.data.tipsContent, "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
              })
                .then(() => {
                  this.doDel(
                    {
                      url: "/biz/userGive/cancel",
                      msg: "你确定要赠送撤销吗？",
                      ps: {
                        type: "post",
                        data: { giveId },
                      },
                    },
                    true,
                    2
                  );
                }).catch(() => {});
            } else {
              this.doDel(
                {
                  url: "/biz/userGive/cancel",
                  msg: "你确定要赠送撤销吗？",
                  ps: {
                    type: "post",
                    data: { giveId },
                  },
                },
                true,
                2
              );
            }
          }
        }
      );
    },
    //上传附件
    uploadFile(giveId) {
      this.uploadFileDialog = true;
      this.giveId = giveId;
      this.getFileList(giveId);
    },
    getFileList(giveId) {
      this.$post("/biz/userGive/annex/list", { giveId }, 3000, true, 2)
        .then((ret) => {
          this.uploadFileList = ret.data || [];
        })
        .catch((err) => {
          return;
        });
    },
    uploadChange(file) {
      this.fileName = file.name;
      let size = file.size / 1024 / 1024;
      let fileName = file.name.split(".");
      let extension = file.name.substring(file.name.lastIndexOf(".") + 1);
      const isXLSX = extension === "PDF";
      const isxlsx = extension === "pdf";
      const jpg = extension === "jpg";
      const png = extension === "png";
      // const isXLS = extension === "XLS";
      // const isxls = extension === "xls";
      if (!isXLSX && !isxlsx && !jpg && !png) {
        this.$message.error("只能上传后缀是.pdf或.PDF或.jpg或.png的文件");
        return;
      }
      if (size > 10) {
        this.$message.error("文件大小不能超过10M");
        return;
      }
      let formData = new FormData();
      formData.append("folder ", "USER");
      formData.append("file ", file.raw);
      formData.append("fileType ", extension);

      this.$Postformat("/sys/upload", formData)
        .then((result) => {
          this.uploadFileList.push({
            annexName: file.name,
            annexFile: result.data.fileKey,
            annexType: extension,
            annexFileUrl: result.data.fileURL,
          });
          this.$post(
            "/biz/userGive/annex/insert",
            {
              giveId: this.giveId,
              annexFile: result.data.fileKey,
              annexName: file.name,
              annexType: extension,
            },
            3000,
            true,
            2
          ).then((ret) => {
            //   if(ret.status == 0) {
            //       console.log(ret);
            //     //   this.getFileList(giveId)
            //   }
          });
        })
        .catch((err) => {
          return;
        });
    },
    //删除附件
    removeExl(index, annexId) {
      if (annexId) {
        this.$post(
          "/biz/userGive/annex/delete",
          { annexId },
          3000,
          true,
          2
        ).then((ret) => {
          if (ret.status == 0) {
            this.$message({
              message: ret.message,
              type: "success",
            });
            this.uploadFileList.splice(index, 1);
            this.getFileList(this.giveId);
          }
        });
      } else {
        this.uploadFileList.splice(index, 1);
      }

      this.$forceUpdate();
    },
    lookExl(item) {
      this.previewLoading = true;
      this.annexType = item.annexType;
      if (item.annexType == "pdf" || item.annexType == "PDF") {
        this.$nextTick(() => {
          pdf.embed(item.annexFileUrl, "#pdf-cert1");
        });
      } else {
        this.ImgSrc = item.annexFileUrl;
      }
    },
    getTableHeight(opDom = true, page = true) {
      let tHeight =
        window.innerHeight - (3 + 1.35 + 3.25 + 0.675 + 2 + 1.35 + 1.5) * 16;
      if (opDom) {
        tHeight -= 40 + 0.675 * 16 + 1;
      }
      if (page) {
        tHeight -= 40;
      }
      this.tableHeight = tHeight;
      // // 40为thead高 | trHeight默认45为带按钮的tbody的tr高
      // this.pageSize = Math.ceil((tHeight -40) / trHeight);
    },
    // 下载模板
    downloadTheTemplate(){
      this.$post(
          "/biz/userGive/userImport/template",
          {},
          3000,
          true,
          2
        ).then((ret) => {
          if (ret.status == 0) {
            window.open(ret.message)
            // let link = document.createElement("a");
            // link.style.display = "none";
            // link.href = ret.message;
            // link.setAttribute("download", " 批量导入模板.xls");
            // document.body.appendChild(link);
            // link.click();
          }
        });
    },
    /* 上传文件 */
    uploadChange2(file) {
      let size = file.size / 1024 / 1024;
      let extension = file.name.substring(file.name.lastIndexOf(".") + 1);
      const isXLSX = extension === "XLSX";
      const isxlsx = extension === "xlsx";
      const isXLS = extension === "XLS";
      const isxls = extension === "xls";
      if (!isXLSX && !isxlsx && !isXLS && !isxls) {
        this.$message.error("只能上传后缀是.xlsx或者.xls的文件");
        return;
      }
      if (size > 50) {
        this.$message.error("文件大小不能超过50M");
        return;
      }
      this.Form.fileName = file.name;
      let formData = new FormData();
      formData.append("folder ", "TEMP");
      formData.append("file ", file.raw);
      formData.append("fileType ", extension);
      this.$Postformat("/sys/upload", formData)
        .then((result) => {
          this.Form.fileKey = result.data.fileKey;
        })
        .catch(() => {
          setTimeout(() => {
            this.$message({
              type: "warning",
              message: "保存失败",
            });
          }, 300);
        });
      this.$forceUpdate();
    },
    // 导出
    exportList(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
      };
      if (this.content) {
        params.content = this.content;
      }
      if (this.signDate&&this.signDate.length===2) {
        params.startTime = this.signDate[0];
        params.endTime = this.signDate[1];
      }
      if (this.giveType) {
        params.giveType = this.giveType;
      }
      if (this.giveMode) {
        params.giveMode = this.giveMode;
      }
      this.$post("/biz/userGive/list/export",params,3000,true,2)
      .then((res) => {
        const arr=[{...res.data}];
        for (let item of arr) {
          if (!this.downloadItems.includes(item.taskId)) {
            this.$store.dispatch("pushDownloadItems", item.taskId);
          } else {
            this.$message.warning(
                "[" + item.fileName + "]已经申请下载,请耐心等待"
            );
          }
        }
      })
      .catch(() => {
        return;
      });
    },
      //获取导入进度
    doProgress(batchId) {
      this.$post(
        "/biz/userGive/import/insert/progress",
        { batchId },
        5000,true,2
      ).then((ret) => {
        if (!ret.data.status) {
          this.progressVal = ret.data.progress;
          setTimeout(() => {
            this.doProgress(batchId);
          }, 2000);
        } else {
          if (ret.data.message) {
            this.$message({
              type: "error",
              message: ret.data.message
            });
          } else {
            this.totalNum = ret.data.totalNum;
            this.errorNum = ret.data.errorNum;
            this.correctNum = ret.data.correctNum;
            this.batchId = ret.data.batchId;
            this.progressVal = ret.data.progress;
            this.errorFileUrl = ret.data.errorFileUrl;
            if(this.errorNum==0){
              this.$message({
                type: "success",
                message: "导入成功",
              });
              this.canCleForm();
            }else{
              this.$message({
                type: "error",
                message: "导入数据中存在错误，请查看导入结果！",
              });
            }
          }
        }
      });
    },
    doExportError(){
      window.open(this.errorFileUrl)
    },
    // 查看名单
    examineRoll(giveId){
      this.checkTheListState = true
      this.giveId = giveId
      this.getExamineRoll(1)
    },
    canCleList(){
      this.dialogData={
        userName:'',
        mobile:''
      }
      this.checkTheListState = false
    },
    sizeChange2(pageSize) {
      this.pageSize2 = pageSize;
      this.getExamineRoll();
    },
    indexMethod2(index) {
      return (this.pageNum2 - 1) * this.pageSize2 + index + 1;
    },
    // get查看名单
    getExamineRoll(pageNum=1){
       const params = {
        pageNum: pageNum,
        pageSize: this.pageSize2,
        giveId:this.giveId
      };
      if (this.dialogData.mobile) {
        params.mobile = this.dialogData.mobile;
      }
      if (this.dialogData.userName) {
        params.userName = this.dialogData.userName;
      }
      this.$post(
        "/biz/userGive/user/pageList",params,5000,true,2
      ).then(({data}) => {
        this.apiData2 = {
          total: data.total || 0,
          size: this.pageSize2 || 10,
          current: this.pageNum2 || 1,
        };
        this.checkTheList = data.list
      });
    }
  },
  beforeRouteLeave: resetKeepAlive,
  watch: {
    $route: {
      handler: function (val, oldVal) {
        if (val.query.refresh) {
          this.getData(-1);
        }
      },
      // 深度观察监听
      deep: true,
    },
  },
};
</script>
<style lang="less" scope>
.tip {
  cursor: pointer;
  background: #f2f6fc;
}
.my-btn {
  width: 5rem;
  height: 28px;
  margin-top: 6px;
  .el-upload {
    width: 5rem;
    height: 28px !important;
    border: none !important;
  }
}
.el-upload {
  border: none;
  height: 180px !important;
}
.export-box {
    width: 100%;
    margin-bottom: 20px;
    h1 {
      padding: 0 5px 10px;
      display: flex;
      align-items: flex-end;
      font-size: 40px;
      border-bottom: 1px dashed #ccc;
      span {
        margin-left: 20px;
        font-size: 14px;
      }
    }
    > div {
      padding-left: 30px;
    }
    .el-button.is-disabled,
    .el-button.is-disabled:focus,
    .el-button.is-disabled:hover {
      color: #fff;
      background: #c3c3c3;
      border: none;
    }
  }
</style>
<style lang="less">
.el-tooltip__popper {
  max-width: 45% !important;
}
</style>